import React, { PureComponent } from 'react';
import { TweenMax } from 'gsap';
import './openNavbar.css';
import Icon from '../../icons';
import style from './openNavbar.css';

export default class OpenNavbar extends PureComponent {

    listener = null;

    state = {
        iconDimension: 50
    }

    componentDidMount() {

        let listener = window.addEventListener("resize", () => {
            let { innerWidth } = window
            if (window.innerWidth < 700) {
                this.setState({
                    iconDimension: 30
                })
            } else if (window.innerWidth < 1000) {
                this.setState({
                    iconDimension: 40
                })
            } else {
                this.setState({
                    iconDimension: 50
                })
            }
        })
        // TweenMax.to("nav", 0.2, { backgroundColor: "rgba(31, 40, 51, 0.0)" }, 0);
        TweenMax.from(".top-nav-secondary", 0.7, { x: window.innerWidth, opacity: 0, delay: 0.1 });
        TweenMax.to(".top-nav-secondary", 0.7, { x: window.innerWidth - this.topNav.innerWidth, opacity: 1.0, delay: 0.1 });
    }

    componentWillReceiveProps(nextProps) {
        console.log("Next Props: ", nextProps);
        if (!nextProps.isMounted) {
            TweenMax.to(".top-nav-secondary", 0.7, { x: window.innerWidth, opacity: 0, display: 'none' });
            // TweenMax.to("nav", 0.2, { backgroundColor: "rgba(31, 40, 51, 0.95)"});
        }
    }
    _handleIconGrow = (id) => {
        console.log("This icon is growing: ", id);
        TweenMax.to(id, 0.2, { scale: 1.2, opacity: 0.8, transformOrigin: "50% 50%" })
    }

    _handleIconShrink = (id) => {
        console.log("shrinking!");
        TweenMax.to(id, 0.2, { scale: 1.0, opacity: 1.0, transformOrigin: "50% 50%" })
    }

    render() {
        console.log("This.props: ", this.props)
        const Option = (props) => {

            let option = null;

            let onClick = () => {
                console.log("Clicked!");
                let baseLocation = '';
                const idReg = /[#]+/;
                window.location.href = props.location
                this.props.toggle();
            }

            let onMouseEnter = () => {
                TweenMax.to(option, 0.2, { scale: 1.1, color: '#66FCF1' })
            }

            let onMouseLeave = () => {
                TweenMax.to(option, 0.2, { scale: 1, color: 'grey' })
            }

            return (
                <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={e => option = e} onClick={onClick} className={style.option}>{props.label}</li>
            )
        }

        return (
            <div ref={e => this.topNav = e} className="top-nav-secondary">
                <div className={style.logoWrapper}>
                    <img alt="Now We Know Logo" className={style.logo} src={process.env.PUBLIC_URL + '/images/nwk_logos/nwk_bold.png'} />
                </div>
                <div className={style.scrollable}>
                    <div className={style.flexWrapper}>
                        <ul className={style.optionsList}>
                            <Option location="/#landing" label="Home" />
                            <Option location="/#information" label="About" />
                            <Option location="/#featured" label="Content" />
                            <Option location="/#host" label="Our Host" />
                            <Option location="/#values" label="The Team" />
                            <Option location="/#distribution" label="Distribution" />
                            {/* <Option location="/#contact" label="Contact" /> */}
                        </ul>
                        {/* <div className={style.socialText}>
                            <h2>Follow us on social media:</h2>
                        </div>
                        <div className={style.socialMediaIcons}>
                            <span>
                                <Icon onMouseEnter={this._handleIconGrow} onMouseLeave={this._handleIconShrink} type="facebook" color={"white"} height={this.state.iconDimension} filled={true} />
                            </span>
                            <span>
                                <Icon onMouseEnter={this._handleIconGrow} onMouseLeave={this._handleIconShrink} type="instagram" color={"white"} width={this.state.iconDimension} filled={true} />
                            </span>
                            <span>
                                <Icon onMouseEnter={this._handleIconGrow} onMouseLeave={this._handleIconShrink} type="twitter" color={"white"} width={this.state.iconDimension} filled={true} />
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }


}